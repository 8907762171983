import React from 'react';

import { Link } from '../components/atoms/link';
import { PageTemplate } from '../components/templates/page-template';

const IndexPage: React.FC = () => {
  return (
    <PageTemplate title="Page not found">
      <Link href="/">Go back to documentation overview</Link>
    </PageTemplate>
  );
};

export default IndexPage;
